import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import TitleBox from "../components/titleBox"
import BlockContentPrimary from "../components/BlockContentPrimary"

const Exhibitors = ({ data }) => {
  const { title, _rawDescription } = data.exhibitorPage

  return (
    <Layout>
      <SEO title={title} />
      <TitleBox title={title} className="flex-grow" background={null}>
        <div className="pageContent">
          <BlockContentPrimary blockData={_rawDescription} />
        </div>
      </TitleBox>
    </Layout>
  )
}

export const query = graphql`
  query ExhibitorsQuery {
    exhibitorPage: sanityExhibitors {
      title
      _rawDescription
    }
  }
`

export default Exhibitors
